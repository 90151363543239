<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Pagamento') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Data') }}</h6>
                  {{pagamento.datetime}}
                </div>
                <div class="col-6">
                  <h6>{{ $t('Prenotazione') }}</h6>
                  <router-link :to="{name:'Prenotazione', params:{id: pagamento.id_prenotazione}}">
                    # {{ pagamento.id_prenotazione }}
                  </router-link>
                </div>
              </div>
              <div v-if="pagamento.id_spazio > 0 || pagamento.id_edificio > 0" class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Spazio') }}</h6>
                  <router-link :to="{name:'Spazio', params:{id: pagamento.id_spazio}}">
                    <span v-if="lang === 'it'">{{ pagamento.nome }}</span>
                    <span v-else-if="lang === 'en'">{{ pagamento.nome_en }}</span>
                    <span v-else-if="lang === 'de'">{{ pagamento.nome_de }}</span>
                    <span v-else></span>
                  </router-link>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Edificio') }}</h6>
                  <router-link :to="{name:'Edificio', params:{id: pagamento.id_edificio}}">
                    {{ pagamento.nome_edificio }}
                  </router-link>
                </div>
              </div>
              <div v-if="pagamento.id_utente>0" class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Utente') }}</h6>
                  <router-link :to="{name:'Utente', params:{id: pagamento.id_utente}}">
                    {{pagamento.nome_utente}} {{pagamento.cognome_utente}}<br>
                  </router-link>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Email') }}</h6>
                  <a :href="'mailto:'+pagamento.email_utente">{{pagamento.email_utente}}</a>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Importo') }}</h6>
                  <argon-input
                      id="importo"
                      v-model="pagamento.importo"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                  />
                </div>
                <div class="col-6">
                  <h6>{{ $t('Mezzo') }}</h6>
                  <select id="choices-mezzo" v-model="pagamento.mezzo" class="form-control" name="choices-mezzo" required>
                    <option value="stripe">{{$t('Stripe')}}</option>
                    <option value="manuale">{{$t('Manuale')}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Stato') }}</h6>
                  <select id="choices-status" v-model="pagamento.payment_status" class="form-control" name="choices-status" required>
                    <option value="completed">{{$t('Completato')}}</option>
                    <option value="planned">{{$t('Pianificato')}}</option>
                  </select>
                </div>
                <div class="col-6">
                  <h6>{{ $t("Rata?") }}</h6>
                  <select id="choices-rata" v-model="pagamento.is_rata" class="form-control" name="choices-rata" required>
                    <option value="0">{{$t('No')}}</option>
                    <option value="1">{{$t('Si')}}</option>
                  </select>
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {savedModal, unsavedModal} from "@/modals";
import {mapState} from "vuex";

export default {
  name: "Pagamento",
  components: {ArgonButton, ArgonInput},
  data() {
    return {
      pagamento: {
        id: 0,
        id_prenotazione: 0,//inizializzato a ?id_prenotazione= in created
        nome_edificio: '',
        nome: '',
        nome_en: '',
        nome_de: '',
        payment_status: 'completed',
        mezzo: 'manuale',
        importo: 0,
        is_rata: 0,
        id_utente: 0,
        nome_utente: '',
        cognome_utente: '',
        email_utente: '',
        datetime: this.formatDate(new Date())
      },
      pagamentoKey: Math.random(),
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    if(this.$route.query.id_prenotazione){
      this.pagamento.id_prenotazione = parseInt(this.$route.query.id_prenotazione);
    }

    this.loadPagamento(this.$route.params.id);
  },
  methods: {
    loadPagamento(id){
      this.pagamento.id = parseInt(id);
      if(this.pagamento.id !== 0){
        this.apiClient.getPagamento(id).then(response => {
          if(response.data){
            this.pagamento = response.data;
            this.pagamentoKey = Math.random();
          }
        });
      }
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.savePagamento(this.pagamento).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.back();
        }else{
          unsavedModal(this);
        }
      });
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
            this.padTo2Digits(date.getSeconds()),
          ].join(':')
      );
    }
  }
};
</script>
