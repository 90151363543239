<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Descrizione") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Prezzo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Quantità Massima") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t('Notifica Email') }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="s in servizi_listino" :key="'servizi-datatable-'+s.id">
        <td><strong>{{ s.id }}</strong></td>
        <td v-if="lang === 'it'">{{ s.descrizione }}</td>
        <td v-else-if="lang === 'en'">{{ s.descrizione_en }}</td>
        <td v-else-if="lang === 'de'">{{ s.descrizione_de }}</td>
        <td v-else></td>
        <td>{{ s.prezzo }} CHF</td>
        <td>{{ s.quantita_max }}</td>
        <td>{{s.cc_email}}</td>
        <td>
          <router-link :to="{name:'ServizioListino', params:{id: s.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
          <argon-button class="ms-1" size="sm" color="danger" @click.prevent="remove(s.id)"><span class="fa fa-trash-can"></span></argon-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import {mapState} from "vuex";
import {removeModal} from "@/modals";

export default {
  name: "ServizioListinoDataTable",
  components: {
    ArgonButton
  },
  props: {
    servizi_listino: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
  methods: {
    remove: function(id){
      removeModal(this, () => {
        this.apiClient.removeServizioListino(id).then(() => {
          location.reload();//si, si dovrebbe fare una emit e intercettarla nel padre ecc.. non ho tempo
        });
      });
    }
  }
};
</script>
