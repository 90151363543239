<template>
  <argon-badge v-if="type == 'extra'" size="sm" color="success">{{$t('Servizio Extra')}}</argon-badge>
  <argon-badge v-else-if="type == 'qrcode'" size="sm" color="primary">{{$t('Listino QR')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Sconosciuto')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "AltriPagamentiTypeBadge",
  components: {ArgonBadge},
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
