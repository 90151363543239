<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Servizio') }}</h5>
            </div>
            <div class="card-body">
              <div v-if="servizio.id > 0 && servizio.id_spazio > 0" class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Spazio') }}</h6>
                  <router-link :to="{name:'Spazio', params:{id: servizio.id_spazio}}">
                    <span v-if="lang === 'it'" size="sm">{{servizio.nome_spazio}}</span>
                    <span v-else-if="lang === 'en'" size="sm">{{servizio.nome_spazio_en}}</span>
                    <span v-else-if="lang === 'de'" size="sm">{{servizio.nome_spazio_de}}</span>
                  </router-link>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Nome') }}</h6>
                  <multilang-input
                      id="descrizione"
                      v-model:value-it="servizio.descrizione"
                      v-model:value-en="servizio.descrizione_en"
                      v-model:value-de="servizio.descrizione_de"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Prezzo') }}</h6>
                  <argon-input v-model="servizio.prezzo" type="number" min="0" step="0.01" required/>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Ricorrenza') }}</h6>
                  <select v-model="servizio.ricorrenza" class="form-control" required>
                    <option value="0">{{$t('No')}}</option>
                    <option value="1">{{$t('Giornaliero')}}</option>
                    <option value="7">{{$t('Settimanale')}}</option>
                    <option value="15">{{$t('Bisettimanale')}}</option>
                    <option value="30">{{$t('Mensile')}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Tipo') }}</h6>
                  <select v-model="servizio.tipologia" class="form-control" name="choices-type" required>
                    <option value="incluso">{{$t('Incluso')}}</option>
                    <option value="aggiuntivo">{{$t('Aggiuntivo')}}</option>
                    <option value="obbligatorio">{{$t('Obbligatorio')}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Descrizione') }}</h6>
                  <multilang-textarea
                      id="descrizione_estesa"
                      v-model:value-it="servizio.descrizione_estesa"
                      v-model:value-en="servizio.descrizione_estesa_en"
                      v-model:value-de="servizio.descrizione_estesa_de"
                  />
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import MultilangTextarea from "@/thecomponents/MultilangTextarea.vue";
import MultilangInput from "@/thecomponents/MultilangInput.vue";
import {savedModal, unsavedModal} from "@/modals";
import {mapState} from "vuex";

export default {
  name: "Servizio",
  components: {MultilangInput, MultilangTextarea, ArgonButton, ArgonInput},
  data() {
    return {
      servizio: {
        id: 0,
        id_spazio: 0,//inizializzato a ?sid= in created
        descrizione: '',
        descrizione_en: '',
        descrizione_de: '',
        descrizione_estesa: '',
        descrizione_estesa_en: '',
        descrizione_estesa_de: '',
        prezzo: 0,
        tipologia: 'incluso'
      },
      servizioKey: Math.random(),
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    if(this.$route.query.sid){
      this.servizio.id_spazio = parseInt(this.$route.query.sid);
    }

    this.loadServizio(this.$route.params.id);
  },
  methods: {
    loadServizio(id){
      this.servizio.id = parseInt(id);
      if(this.servizio.id !== 0){
        this.apiClient.getServizio(id).then(response => {
          if(response.data){
            this.servizio = response.data;
            this.servizioKey = Math.random();
          }
        });
      }
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.saveServizio(this.servizio).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Spazio', params: {id: this.servizio.id_spazio}});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
