<template>
  <div class="form-group">
    <quill-editor
      :content="modelValue"
      :placeholder="placeholder"
      contentType="html"
      theme="snow"
      toolbar="essential"
      @update:content="$emit('update:modelValue', $event)"
    />
  </div>
</template>
<style>
.ql-editor{
  height: 250px !important;
  max-height: 250px !important;
}
</style>
<script>
import { QuillEditor } from '@vueup/vue-quill'
export default {
  name: "EditorTextarea",
  components: {QuillEditor},
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ['update:modelValue'],
};
</script>
