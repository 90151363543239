<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Altri Pagamenti")}}</h5>
          </div>
          <div class="card-body">
            <altri-pagamenti-data-table :key="altriPagamentiKey" :altri-pagamenti="altriPagamenti"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AltriPagamentiDataTable from "@/parts/DataTables/AltriPagamentiDataTable.vue";

export default {
  name: "AltriPagamenti",
  components: {AltriPagamentiDataTable},
  data() {
    return {
      altriPagamenti: [],
      altriPagamentiKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getAltriPagamenti().then(response => {
      if(response.data){
        this.altriPagamenti = response.data;
        this.altriPagamentiKey = Math.random();
      }
    });
  },
};
</script>
