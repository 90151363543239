<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                </div>
                <div class="card-body">
                  <form role="form" @submit="login">
                    <argon-alert v-if="invalidLogin" class="font-weight-light" color="danger" dismissible>
                      {{ $t('Credenziali errate') }}
                    </argon-alert>
                    <div class="mb-3">
                      <argon-input ref="email" v-model="email" type="email" placeholder="Email" name="email" size="lg" required/>
                    </div>
                    <div class="mb-3">
                      <argon-input ref="password" v-model="password" type="password" placeholder="Password" name="password" size="lg" minlength="8" required/>
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >Login</argon-button>
                    </div>
                  </form>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapMutations } from 'vuex';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "Signin",
  components: {
    ArgonInput,
    ArgonButton,
    ArgonAlert,
  },
  data(){
    return {
      invalidLogin: false,
      email: "",
      password: "",
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(['setLoginData']),
    async login(event) {
      event.preventDefault();

      try {
        const response = await this.apiClient.login(this.email, this.password);

        if(response.data.success){
          this.invalidLogin = false;
          this.setLoginData(response.data);
          this.$router.push('/dashboard');
        }else{
          this.invalidLogin = true;
        }
      } catch (error) {
        console.error('Errore durante il login:', error);
      }
    },
  },
};
</script>
