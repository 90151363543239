<template>
  <argon-badge v-if="type == 'completed'" size="sm" color="success">{{$t('Completato')}}</argon-badge>
  <argon-badge v-else-if="type == 'payed'" size="sm" color="success">{{$t('Confermata')}}</argon-badge>
  <argon-badge v-else-if="type == 'planned'" size="sm" color="primary">{{$t('Pianificato')}}</argon-badge>
  <argon-badge v-else-if="type == 'cancelled'" size="sm" color="danger">{{$t('Cancellata')}}</argon-badge>
  <argon-badge v-else-if="type == 'canceled'" size="sm" color="danger">{{$t('Cancellata')}}</argon-badge>
  <argon-badge v-else-if="type == 'pending'" size="sm" color="primary">{{$t('In attesa')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Sconosciuto')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "PagamentoStatusBadge",
  components: {ArgonBadge},
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
