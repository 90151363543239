<template>
  <argon-badge v-if="role == 10" size="sm">{{$t('Admin')}}</argon-badge>
  <argon-badge v-else-if="role == 5" size="sm" color="warning">{{$t('Admin Agenzia')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Utente')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "UserRoleBadge",
  components: {ArgonBadge},
  props: {
    role: {
      type: String,
      default: ""
    }
  }
};
</script>
