<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="to" :class="{ active: isParentActive(to) }">
      <span class="sidenav-mini-icon"> {{ miniIcon }} </span>
      <span class="sidenav-normal"> {{ text }} </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    isParentActive(to){
      const currentPath = this.$route.path;
      return to.prefixes.some((p) => currentPath.startsWith(p));
    }
  }
};
</script>
