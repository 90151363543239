<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Sconto') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Codice') }}</h6>
                  <argon-input
                      id="codice"
                      v-model="sconto.codice"
                      required
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Ammontare') }}</h6>
                  <argon-input
                      id="ammontare"
                      v-model="sconto.ammontare"
                      type="number"
                      min="0"
                      step="0.01"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Tipologia') }}</h6>
                  <select id="choices-type" v-model="sconto.tipologia" class="form-control" name="choices-type" required>
                    <option value="percentuale">{{$t("Percentuale")}}</option>
                    <option value="fisso">{{$t("Fisso")}}</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Edificio') }}</h6>
                  <select id="choices-edificio" v-model="sconto.id_edificio" class="form-control" name="choices-edificio" required>
                    <option value="0">{{$t('Tutti')}}</option>
                    <option v-for="e in edifici" :key="'edificio-'+e.id" :value="e.id">{{e.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {savedModal, unsavedModal} from "@/modals";

export default {
  name: "Sconto",
  components: {ArgonButton, ArgonInput},
  data() {
    return {
      sconto: {
        id: 0,
        codice: "",
        ammontare: "",
        tipologia: "",
        id_edificio: 0
      },
      edifici: [],
    };
  },
  created() {
    this.loadSconto(this.$route.params.id);
  },
  methods: {
    loadSconto(id){
      this.sconto.id = parseInt(id);
      if(this.sconto.id !== 0) {
        this.apiClient.getSconto(id).then(response => {
          if (response.data) {
            console.log(response.data);
            this.sconto = response.data;
          }
        });
      }
      if(this.edifici.length === 0){
        this.apiClient.getEdifici().then(response => {
          if(response.data){
            response.data.sort((a, b) => a.nome.localeCompare(b.nome));
            this.edifici = response.data;
          }
        });
      }
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.saveSconto(this.sconto).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Sconti'});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
