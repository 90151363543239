<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Spazio") }}
        </th>
        <th v-if="showUtente" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Utente") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Arrivo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Partenza") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Stato") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="p in prenotazioni" :key="'prenotazione-datatable-'+p.id">
        <td><strong>{{ p.id }}</strong></td>
        <td>
          <router-link :to="{name:'Spazio', params:{id: p.id_spazio}}">
          # {{ p.id_spazio }}
          </router-link>
          <br>
          <router-link :to="{name:'Spazio', params:{id: p.id_spazio}}">
            <span v-if="lang === 'it'">{{ p.nome }}</span>
            <span v-else-if="lang === 'en'">{{ p.nome_en }}</span>
            <span v-else-if="lang === 'de'">{{ p.nome_de }}</span>
            <span v-else></span>
          </router-link>
          <br>
          <router-link :to="{name:'Edificio', params:{id: p.id_edificio}}">
            {{ p.nome_edificio }}
          </router-link>
        </td>
        <td v-if="showUtente">
          <router-link :to="{name:'Utente', params:{id: p.id_utente}}">
            {{p.nome_utente}} {{p.cognome_utente}}<br>
          </router-link>
          <a :href="'mailto:'+p.email_utente">{{p.email_utente}}</a>
        </td>
        <td>{{ p.arrivo }}</td>
        <td>{{ p.partenza }}</td>
        <td><pagamento-status-badge :type="p.payment_status"/></td>
        <td>
          <router-link :to="{name:'Prenotazione', params:{id: p.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import {mapState} from "vuex";
import PagamentoStatusBadge from "@/thecomponents/PagamentoStatusBadge.vue";

export default {
  name: "PrenotazioniDataTable",
  components: {
    PagamentoStatusBadge,
    ArgonButton
  },
  props: {
    prenotazioni: {
      type: Array,
      default: () => []
    },
    showUtente: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
};
</script>
