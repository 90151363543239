<template>
  <argon-badge v-if="type == 'percentuale'" size="sm" color="primary">{{$t('Percentuale')}}</argon-badge>
  <argon-badge v-else-if="type == 'fisso'" size="sm" color="primary">{{$t('Fisso')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Sconosciuto')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "ScontiTypeBadge",
  components: {ArgonBadge},
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
