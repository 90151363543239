<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 v-if="$route.query.id_prenotazione == 0" class="font-weight-bolder mb-0">{{ $t('Nuova Prenotazione') }}</h5>
              <h5 v-else class="font-weight-bolder mb-0">{{ $t('Modifica Prenotazione') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Utente') }}</h6>
                  <p class="mb-0 text-sm">
                    {{ utente.nome }} {{ utente.cognome }}
                  </p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Email') }}</h6>
                  <p class="mb-0 text-sm">
                    {{ utente.email }}
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Tipo') }}</h6>
                  <p class="mb-0 text-sm">
                    <select id="choices-tipo-prenotazione" v-model="prenotazione.type" class="form-control" name="choices-tipo-prenotazione" required @change="reset">
                      <option value="coworking">{{$t('Coworking')}}</option>
                      <option value="coliving">{{$t('Coliving')}}</option>
                    </select>
                  </p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Spazio') }}</h6>
                  <p class="mb-0 text-sm">
                    <select id="choices-spazio" v-model="prenotazione.id_spazio" class="form-control" name="choices-spazio" required>
                      <option v-for="s in spazi_filtered" :key="'spazio-'+s.id" :value="s.id">{{s.nome}} @ {{s.nome_edificio}}</option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Arrivo') }}</h6>
                  <p class="mb-0 text-sm"></p>
                  <argon-input
                      id="arrivo"
                      v-model="prenotazione.arrivo"
                      :type="prenotazione.type==='coworking' ? 'datetime-local' : 'date'"
                      required
                  />
                </div>
                <div class="col-6">
                  <h6>{{ $t('Partenza') }}</h6>
                  <p class="mb-0 text-sm"></p>
                  <argon-input
                      id="partenza"
                      v-model="prenotazione.partenza"
                      :type="prenotazione.type==='coworking' ? 'datetime-local' : 'date'"
                      required
                  />
                </div>
              </div>
              <div v-if="prenotazione.id_spazio && prenotazione.partenza && prenotazione.arrivo">
                <div v-if="!info.disponibile" class="row mt-3">
                  <div class="col-12">
                    {{$t("Spazio non disponibile in queste date")}}
                  </div>
                </div>
                <div v-else>
                  <div class="row mt-3">
                    <div class="col-6">
                      <h6>{{ $t('Servizi') }}</h6>
                      <argon-checkbox v-for="s in info.servizi" :key="'servizio-'+s.id" v-model="s.selezionato" :disabled="s.obbligatorio">
                        <p class="mb-0 text-sm"><strong>{{s.descrizione}}</strong> <span v-html="s.descrizione_estesa"></span></p>
                        <p class="mb-0 text-sm">
                          CHF {{s.prezzo}}
                          <span v-if="s.ricorrenza==='giornaliero'">/ {{$t('giorno')}}</span>
                          <span v-if="s.ricorrenza==='settimanale'">/ {{$t('settimana')}}</span>
                          <span v-if="s.ricorrenza==='mensile'">/ {{$t('mese')}}</span>
                        </p>
                      </argon-checkbox>
                    </div>
                    <div class="col-6">
                      <div>
                        <h6>{{ $t('Stato Prenotazione') }}</h6>
                        <select id="choices-payment_status" v-model="prenotazione.payment_status" class="form-control" name="choices-payment_status" required>
                          <option value="pending">{{"In attesa"}}</option>
                          <option value="payed">{{"Confermata"}}</option>
                          <option value="canceled">{{"Cancellata"}}</option>
                        </select>
                      </div>
                      <div v-if="prenotazione.type==='coliving'" class="mt-3">
                        <h6>{{ $t('Tipo Pagamento') }}</h6>
                        <select id="choices-tipo_pagamento" v-model="prenotazione.tipo_pagamento" class="form-control" name="choices-tipo_pagamento" required>
                          <option value="completo">{{"Completo"}}</option>
                          <option value="ricorrente">{{"Rateizzato"}}</option>
                        </select>
                      </div>
                      <div class="mt-3">
                        <h6>{{ $t('Totale') }}</h6>
                        <p v-if="info.rate > 0">CHF {{info.acconto.toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('acconto')}}</p>
                        <p v-if="info.rate > 0">CHF {{info.rata.toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} x {{info.rate}} {{$t('rate')}}</p>
                        <p v-if="info.rate > 0">CHF {{info.rata_finale.toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('rata finale')}}</p>
                        <p>CHF {{info.totale.toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="button-row d-flex mt-2">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                      class="ms-auto mb-0"
                      @click="save"
                  >{{$t('Salva')}}</argon-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {savedModal, unsavedModal} from "@/modals";

export default {
  name: "NuovaPrenotazione",
  components: {ArgonButton, ArgonCheckbox, ArgonInput},
  data() {
    return {
      disponibile: false,
      utente: {},
      prenotazione: {
        id_spazio: 0,
        arrivo: '',
        partenza: '',
        type: 'coworking',
        tipo_pagamento: 'completo',
        payment_status: 'payed',
        servizi: []
      },
      spazi: [],
      info: {
        disponibile: false,
        servizi: [],
        totale: 0,
        acconto: 0,
        rate: 0,
        rata: 0,
        rata_finale: 0,
      }
    };
  },
  computed: {
    spazi_filtered() {
      return this.spazi.filter(s => s.tipo === this.prenotazione.type);
    }
  },
  watch: {
    prenotazione: {
      deep: true,
      handler() {
        this.calcoloPrezzo(this.$i18n.locale, this.$route.query.id_prenotazione, this.prenotazione.arrivo, this.prenotazione.partenza, this.prenotazione.id_spazio, this.prenotazione.tipo_pagamento);
      }
    },
    info: {
      deep: true,
      handler() {
        this.calcoloPrezzo(this.$i18n.locale, this.$route.query.id_prenotazione, this.prenotazione.arrivo, this.prenotazione.partenza, this.prenotazione.id_spazio, this.prenotazione.tipo_pagamento);
      }
    }
  },
  created() {
    this.loadUser(this.$route.query.id_utente);
    this.apiClient.getListaSpazi(this.$i18n.locale).then(response => {
      if(response.data){
        response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        this.spazi = response.data;
      }
      this.loadPrenotazione(this.$route.query.id_prenotazione);
    });
  },
  methods: {
    loadUser(id){
      this.apiClient.getUtente(id).then(response => {
        if(response.data){
          console.log(response.data);
          this.utente = response.data;
        }
      });
    },
    loadPrenotazione(id){
      if(id==0){
        return;
      }
      this.apiClient.getPrenotazione(id).then(response => {
        if(response.data){
          console.log(response.data);
          this.prenotazione = response.data;
        }
      });
    },
    calcoloPrezzo(lang, id_vecchia_prenotazione, inizio, fine, spazio, tipo_pagamento){
      if(!inizio || !fine || !spazio){
        if(this.info.disponibile!==false && this.info.servizi!==[]){
          this.info = {
            disponibile: false,
            servizi: []
          };
        }
        return;
      }
      let servizi='';
      this.info.servizi.forEach(s => {
        if(s.selezionato){
          servizi += "&servizio[]="+s.id_servizio
        }
      });

      const updatingKey = servizi + inizio + fine + spazio + tipo_pagamento;
      if(this.updating === updatingKey){
        return;
      }
      this.updating = updatingKey;

      this.apiClient.calcoloPrezzo(lang, id_vecchia_prenotazione, inizio, fine, spazio, tipo_pagamento, servizi).then(response => {
        if(response.data){
          this.info = response.data;
          if(!this.serviziInizializzati){
            for(let i = 0; i < this.prenotazione.servizi.length; i++){
              for(let j = 0; j < this.info.servizi.length; j++){
                if(this.prenotazione.servizi[i].id === this.info.servizi[j].id_servizio){
                  this.info.servizi[j].selezionato = true;
                }
              }
            }
          }
          this.serviziInizializzati = true;
        }
      }).catch(reason => {
        console.log(reason);
        this.info = {
          disponibile: false,
          servizi: []
        };
      });
    },
    reset(){
      this.prenotazione.id_spazio = undefined;
      this.prenotazione.arrivo = undefined;
      this.prenotazione.partenza = undefined;
      this.info = {
        disponibile: false,
        servizi: []
      };
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.savePrenotazione(this.$route.query.id_utente, this.$route.query.id_prenotazione, this.prenotazione, this.info.servizi).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Prenotazione', params: {id: response.data.id}});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
