<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Pagamenti Prenotazioni")}}</h5>
          </div>
          <div class="card-body">
            <pagamenti-data-table :key="pagamentiKey" :pagamenti="pagamenti"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagamentiDataTable from "@/parts/DataTables/PagamentiDataTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "Pagamenti",
  components: {ArgonButton, PagamentiDataTable},
  data() {
    return {
      pagamenti: [],
      pagamentiKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getPagamenti().then(response => {
      if(response.data){
        this.pagamenti = response.data;
        this.pagamentiKey = Math.random();
      }
    });
  },
};
</script>
