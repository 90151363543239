<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <sidenav-item
        :to="{ name: 'Dashboard', prefixes: ['/dashboard'] }"
        mini-icon="D"
        :text="$t('Dashboard')"
      />
      <sidenav-item
          :to="{ name: 'Calendari', prefixes: ['/calendari'] }"
          mini-icon="D"
          :text="$t('Calendari')"
      />
      <sidenav-item
          :to="{ name: 'Prenotazioni', prefixes: ['/prenotazioni'] }"
          mini-icon="D"
          :text="$t('Prenotazioni')"
      />
      <sidenav-item
          :to="{ name: 'Pagamenti', prefixes: ['/pagamenti'] }"
          mini-icon="D"
          :text="$t('Pagamenti Prenotazioni')"
      />
      <sidenav-item
          :to="{ name: 'AltriPagamenti', prefixes: ['/altri-pagamenti'] }"
          mini-icon="D"
          :text="$t('Altri Pagamenti')"
      />
      <sidenav-item
          :to="{ name: 'Listini', prefixes: ['/listini', '/listino', '/servizio-listino'] }"
          mini-icon="D"
          :text="$t('Listini QR')"
      />
      <sidenav-item
          :to="{ name: 'Sconti', prefixes: ['/sconti'] }"
          mini-icon="D"
          :text="$t('Sconti')"
      />
      <sidenav-item
        :to="{ name: 'Edifici', prefixes: ['/edifici', '/spazi', '/servizi/'] }"
        mini-icon="D"
        :text="$t('Edifici')"
      />
      <sidenav-item
          v-if="userRole >= 10"
          :to="{ name: 'Utenti', prefixes: ['/utenti'] }"
          mini-icon="D"
          :text="$t('Utenti')"
      />
      <sidenav-item
          :to="{ name: 'Agenzie', prefixes: ['/contatti'] }"
          mini-icon="D"
          :text="$t('Dati di Contatto')"
      />
    </ul>
  </div>
  <div class="mt-3 sidenav-footer">

  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import {mapState} from "vuex";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
  },
  computed: {
    ...mapState(["userRole"])
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
