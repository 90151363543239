<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-8">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <div v-if="spazio.id > 0" class="float-end">
                <router-link :to="{name:'Calendario', params:{id: spazio.id}}">
                  <argon-button class="ms-1" size="sm" color="info"><span class="fa fa-calendar"></span></argon-button>
                </router-link>
              </div>
              <h5 class="font-weight-bolder mb-0">{{ $t('Spazio') }}</h5>
            </div>
            <div class="card-body">
              <div v-if="spazio.id > 0 && spazio.id_edificio > 0" class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Edificio') }}</h6>
                  <router-link :to="{name:'Edificio', params:{id: spazio.id_edificio}}">
                    <span size="sm">{{spazio.nome_edificio}}</span>
                  </router-link>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Nome') }}</h6>
                  <multilang-input
                      id="nome"
                      v-model:value-it="spazio.nome"
                      v-model:value-en="spazio.nome_en"
                      v-model:value-de="spazio.nome_de"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Superficie') }}</h6>
                  <argon-input v-model="spazio.superficie" type="number" required/>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Postazioni') }}</h6>
                  <argon-input v-model="spazio.postazioni" type="number" required/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Permanenza Minima') }}</h6>
                  <select id="choices-type" v-model="spazio.permanenza_minima" class="form-control" name="choices-type" required>
                    <option value="nessuno">{{ $t('Nessuna') }}</option>
                    <option value="ora">{{ $t('Oraria') }}</option>
                    <option value="giorno">{{ $t('Giornaliera') }}</option>
                    <option value="mese">{{ $t('Mensile') }}</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Tipo Serratura') }}</h6>
                  <select id="choices-type" v-model="spazio.tipo_serratura" class="form-control" name="choices-type" required>
                    <option value="dormakaba">{{ $t('Dormakaba') }}</option>
                    <option value="chiave">{{ $t('Chiave') }}</option>
                  </select>
                </div>
              </div>
              <div v-if="spazio.tipo_serratura==='dormakaba'" class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Access Group ID Dormakaba') }}</h6>
                  <argon-input v-model="spazio.access_group_id" type="text" required/>
                </div>
              </div>
              <div v-else-if="spazio.tipo_serratura==='chiave'" class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Istruzioni Accesso') }}</h6>
                  <multilang-textarea
                      id="descrizione"
                      v-model:value-it="spazio.istruzioni_chiave"
                      v-model:value-en="spazio.istruzioni_chiave_en"
                      v-model:value-de="spazio.istruzioni_chiave_de"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-4">
                  <h6>{{ $t('Tariffa Oraria') }}</h6>
                  <argon-input v-model="spazio.prezzo_ora" type="number" min="0" step="0.01"/>
                </div>
                <div class="col-12 col-sm-4">
                  <h6>{{ $t('Tariffa Giornaliera') }}</h6>
                  <argon-input v-model="spazio.prezzo_giorno" type="number" min="0" step="0.01"/>
                </div>
                <div class="col-12 col-sm-4">
                  <h6>{{ $t('Tariffa Mensile') }}</h6>
                  <argon-input v-model="spazio.prezzo_mese" type="number" min="0" step="0.01"/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Descrizione') }}</h6>
                  <multilang-textarea
                      id="descrizione"
                      v-model:value-it="spazio.descrizione"
                      v-model:value-en="spazio.descrizione_en"
                      v-model:value-de="spazio.descrizione_de"
                  />
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4">
        <div class="card p-3 border-radius-xl bg-white h-100">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0">{{ $t('Galleria') }}</h5>
          </div>
          <div class="card-body">
            <div class="row gallery">
              <div v-for="(f, i) in spazio.foto_gallery" :key="f.id" class="col-6 mt-3 img-container">
                <span class="delete-img" @click="deleteImage(i)">&#x274C;</span>
                <image-input v-model="f.url"/>
              </div>
            </div>
            <div class="button-row d-flex mt-2">
              <argon-button
                  type="button"
                  color="dark"
                  variant="gradient"
                  class="ms-auto mb-0"
                  @click="addImage"
              >{{$t('Aggiungi')}}</argon-button>
            </div>
            <p>{{ $t("Clicca sull'immagine per cambiarla") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="spazio.id !== 0" class="row mt-4">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t("Servizi")}}</h5>
              <div class="button-row d-flex">
                <router-link :to="{name:'Servizio', params:{id: 0}, query:{sid: spazio.id}}" class="ms-auto">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                  >{{$t('Nuovo')}}</argon-button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <servizi-data-table :key="serviziKey" :servizi="servizi"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
span.delete-img{
  position: absolute;
  z-index: 99;
  cursor: pointer;
  display: none;
}
.img-container:hover span.delete-img{
  display: initial;
}
</style>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import MultilangTextarea from "@/thecomponents/MultilangTextarea.vue";
import ImageInput from "@/thecomponents/ImageInput.vue";
import MultilangInput from "@/thecomponents/MultilangInput.vue";
import ServiziDataTable from "@/parts/DataTables/ServiziDataTable.vue";
import {savedModal, unsavedModal} from "@/modals";

export default {
  name: "Edificio",
  components: {ServiziDataTable, MultilangInput, ImageInput, MultilangTextarea, ArgonButton, ArgonInput},
  data() {
    return {
      spazio: {
        id: 0,
        id_edificio: 0,//inizializzato a ?eid= in created
        nome: '',
        nome_en: '',
        nome_de: '',
        superficie: '',
        postazioni: '',
        permanenza_minima: 'nessuno',
        tipo_serratura: 'chiave',
        access_group_id: '',
        descrizione: '',
        descrizione_en: '',
        descrizione_de: '',
        foto_gallery: [{
          id: Math.random(),
          url: ''
        }],
        prezzo_ora: 0,
        prezzo_giorno: 0,
        prezzo_mese: 0,
        istruzioni_chiave: '',
        istruzioni_chiave_en: '',
        istruzioni_chiave_de: ''
      },
      servizi: [],
      serviziKey: Math.random(),
      edificioKey: Math.random(),
      fotoGalleryKey: Math.random()
    };
  },
  created() {
    if(this.$route.query.eid){
      this.spazio.id_edificio = parseInt(this.$route.query.eid);
    }

    this.loadSpazio(this.$route.params.id);
  },
  methods: {
    loadSpazio(id){
      this.spazio.id = parseInt(id);
      if(this.spazio.id !== 0){
        this.apiClient.getSpazio(id).then(response => {
          if(response.data){
            this.spazio = response.data;
            this.edificioKey = Math.random();
            this.fotoGalleryKey = Math.random();
          }
        });
        this.apiClient.getServizi(id).then(response => {
          if(response.data){
            this.servizi = response.data;
            this.serviziKey++;
          }
        });
      }
    },
    addImage(){
      this.spazio.foto_gallery.push({
        id: Math.random(),
        url: ''
      });
    },
    deleteImage(i){
      this.spazio.foto_gallery.splice(i, 1);
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.saveSpazio(this.spazio).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Edificio', params: {id: this.spazio.id_edificio}});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
