<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <calendar id="calendar-table" :key="prenotazioniKey" :events="prenotazioni" :title="$t('Prenotazioni')"/>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "@/thecomponents/Calendar.vue";
export default {
  name: "Calendario",
  components: {
    Calendar
  },
  data() {
    return {
      id_spazio: 0,
      prenotazioni: [],
      prenotazioniKey: Math.random()
    };
  },
  created() {
    this.loadPrenotazioni(this.$route.params.id);
  },
  methods: {
    loadPrenotazioni(id){
      this.id_spazio = parseInt(id);
      if(this.id_spazio !== 0){
        this.apiClient.getPrenotazioniSpazio(this.id_spazio, true).then(response => {
          if(response.data){
            let lang = this.$i18n.locale;
            this.prenotazioni = response.data.map(p => {
              return {
                title: (
                    lang==='it'
                        ? p.nome
                        : (
                            lang==='en'
                                ? p.nome_en
                                : p.nome_de
                        )
                )+' @ '+p.nome_edificio,
                start: p.arrivo,
                end: p.partenza,
                className: p.tipo_edificio==='coworking' ? "bg-gradient-success" : "bg-gradient-info",
                prenotazione: p
              };
            });
            this.prenotazioniKey = Math.random();
          }
        });
      }
    },
  }
};
</script>
