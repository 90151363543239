<template>
  <argon-badge v-if="ricorrenza == 0" size="sm" color="success">{{$t('No')}}</argon-badge>
  <argon-badge v-else-if="ricorrenza == 1" size="sm" color="primary">{{$t('Giornaliero')}}</argon-badge>
  <argon-badge v-else-if="ricorrenza == 7" size="sm" color="primary">{{$t('Settimanale')}}</argon-badge>
  <argon-badge v-else-if="ricorrenza == 15" size="sm" color="primary">{{$t('Bisettimanale')}}</argon-badge>
  <argon-badge v-else-if="ricorrenza == 30" size="sm" color="primary">{{$t('Mensile')}}</argon-badge>
  <argon-badge v-else size="sm" color="primary">{{ricorrenza}} {{$t('giorni')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "RicorrenzaBadge",
  components: {ArgonBadge},
  props: {
    ricorrenza: {
      type: String,
      default: ""
    }
  }
};
</script>
