<template>
  <div class="col-lg-4 col-md-6">
    <ul ref="pill" class="bg-transparent nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a class="px-0 py-1 mb-0 nav-link active" data-bs-toggle="tab" role="tab" aria-selected="true" @click="changeLang('it');">
          <img alt="Italiano" src="@/assets/img/flags/it.png">
        </a>
      </li>
      <li class="nav-item">
        <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" role="tab" aria-selected="false" @click="changeLang('en');">
          <img alt="English" src="@/assets/img/flags/en.png">
        </a>
      </li>
      <li class="nav-item">
        <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" role="tab" aria-selected="false" @click="changeLang('de');">
          <img alt="Deutsch" src="@/assets/img/flags/de.png">
        </a>
      </li>
    </ul>
  </div>
  <argon-input  v-if="currentLang==='it'" :id="id+'_it'" v-model="localValueIt" :required="required" @update:modelValue="updateValueIt"/>
  <argon-input  v-if="currentLang==='en'" :id="id+'_en'" v-model="localValueEn" :required="required" @update:modelValue="updateValueEn"/>
  <argon-input  v-if="currentLang==='de'" :id="id+'_de'" v-model="localValueDe" :required="required" @update:modelValue="updateValueDe"/>
</template>
<style>
a {
  cursor: pointer;
}
</style>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  name: "MultilangInput",
  components: {ArgonInput},
  props: {
    id: {
      type: String,
      required: true,
    },
    valueIt: {
      type: String,
      default: "",
    },
    valueEn: {
      type: String,
      default: "",
    },
    valueDe: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    //definisco tre eventi che questo componente emette quando i suoi valori vengono modificati
    'update:valueIt',
    'update:valueEn',
    'update:valueDe',
  ],
  data() {
    //definisco delle variabili locali che mantengono i valori in copia rispetto alle 3 props pubbliche
    return {
      currentLang: 'it',
      localValueIt: this.valueIt,
      localValueEn: this.valueEn,
      localValueDe: this.valueDe,
    };
  },
  watch: {
    //dichiaro 3 watcher per osservare le 3 prop, se cambiano aggiorno le variabili locali
    valueIt(newValue) {
      this.localValueIt = newValue;
    },
    valueEn(newValue) {
      this.localValueEn = newValue;
    },
    valueDe(newValue) {
      this.localValueDe = newValue;
    },
  },
  mounted() {
    setNavPills(this.$refs.pill);
  },
  methods: {
    //se le textarea emettono un evento update:modelValue aggiorno le variabili logali e riemetto l'evento al padre per il v-model
    updateValueIt(value) {
      this.localValueIt = value;
      this.$emit('update:valueIt', value);
    },
    updateValueEn(value) {
      this.localValueEn = value;
      this.$emit('update:valueEn', value);
    },
    updateValueDe(value) {
      this.localValueDe = value;
      this.$emit('update:valueDe', value);
    },
    changeLang(lang){
      this.currentLang = lang;
    }
  },
};
</script>
