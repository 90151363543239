<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-8">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Edificio') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Nome') }}</h6>
                  <argon-input
                      id="nome"
                      v-model="edificio.nome"
                      required
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Indirizzo') }}</h6>
                  <argon-input
                      id="indirizzo"
                      v-model="edificio.indirizzo"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Tipo') }}</h6>
                  <select id="choices-type" v-model="edificio.type" class="form-control" name="choices-type" required>
                    <option value="coworking">Coworking</option>
                    <option value="coliving">Coliving</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Dati di Contatto') }}</h6>
                  <select id="choices-agenzia" v-model="edificio.id_agenzia" class="form-control" name="choices-agenzia" required>
                    <option v-for="a in agenzie" :key="'agenzia-'+a.id" :value="a.id">{{a.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Descrizione') }}</h6>
                  <multilang-textarea
                      id="descrizione"
                      v-model:value-it="edificio.descrizione"
                      v-model:value-en="edificio.descrizione_en"
                      v-model:value-de="edificio.descrizione_de"
                  />
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4">
        <div class="card p-3 border-radius-xl bg-white h-100">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0">{{ $t('Foto') }}</h5>
          </div>
          <div class="card-body">
            <image-input :key="fotoKey" v-model="edificio.foto_url"/>
            <p>{{ $t("Clicca sull'immagine per cambiarla") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="edificio.id !== 0" class="row mt-4">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t("Spazi")}}</h5>
              <div class="button-row d-flex">
                <router-link :to="{name:'Spazio', params:{id: 0}, query:{eid: edificio.id}}" class="ms-auto">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                  >{{$t('Nuovo')}}</argon-button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <spazi-data-table :key="spaziKey" :spazi="spazi"/>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="edificio.id !== 0" class="row mt-4">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t("Servizi Extra")}}</h5>
              <div class="button-row d-flex">
                <router-link :to="{name:'ServizioExtra', params:{id: 0}, query:{eid: edificio.id}}" class="ms-auto">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                  >{{$t('Nuovo')}}</argon-button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <servizi-extra-data-table :key="serviziExtraKey" :servizi_extra="serviziExtra"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import MultilangTextarea from "@/thecomponents/MultilangTextarea.vue";
import SpaziDataTable from "@/parts/DataTables/SpaziDataTable.vue";
import ServiziExtraDataTable from "@/parts/DataTables/ServiziExtraDataTable.vue";
import ImageInput from "@/thecomponents/ImageInput.vue";
import {savedModal, unsavedModal} from "@/modals";

export default {
  name: "Edificio",
  components: {ImageInput, SpaziDataTable, ServiziExtraDataTable, MultilangTextarea, ArgonButton, ArgonInput},
  data() {
    return {
      edificio: {
        id: 0,
        nome: '',
        indirizzo: '',
        type: '',
        id_agenzia: 0,
        descrizione: '',
        descrizione_en: '',
        descrizione_de: '',
        foto_url: ''
      },
      agenzie: [],
      spazi: [],
      serviziExtra: [],
      spaziKey: Math.random(),
      serviziExtraKey: Math.random(),
      edificioKey: Math.random(),
      fotoKey: Math.random()
    };
  },
  created() {
    this.loadEdificio(this.$route.params.id);
    /*
    this.$watch(
      () => this.$route.params,
        // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        this.loadEdificio(toParams.id);
      }
    )
    */
  },
  methods: {
    loadEdificio(id){
      this.edificio.id = parseInt(id);
      if(this.edificio.id !== 0){
        this.apiClient.getEdificio(id).then(response => {
          if(response.data){
            this.edificio = response.data;
            this.edificioKey = Math.random();
            this.fotoKey = Math.random();
          }
        });
        this.apiClient.getSpazi(id).then(response => {
          if(response.data){
            this.spazi = response.data;
            this.spaziKey++;
          }
        });
        this.apiClient.getServiziExtra(id).then(response => {
          if(response.data){
            this.serviziExtra = response.data;
            this.serviziExtraKey++;
          }
        });
      }
      if(this.agenzie.length === 0){
        this.apiClient.getAgenzie(id).then(response => {
          if(response.data){
            response.data.sort((a, b) => a.nome.localeCompare(b.nome));
            this.agenzie = response.data;
          }
        });
      }
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.saveEdificio(this.edificio).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Edifici'});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
