<template>
  <div class="card widget-calendar">
    <div class="p-3 pb-0 card-header">
      <h5 class="font-weight-bolder mb-0">{{ title }}</h5>
    </div>
    <div class="p-3 card-body">
      <div :id="id" data-toggle="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import itLocale from '@fullcalendar/core/locales/it';
import deLocale from '@fullcalendar/core/locales/de';
import tippy, {followCursor} from 'tippy.js';
import 'tippy.js/dist/tippy.css'
let calendar;
export default {
  name: "Calendar",
  props: {
    id: {
      type: String,
      default: "widget-calendar",
    },
    title: {
      type: String,
      default: "",
    },
    initialView: {
      type: String,
      default: "dayGridMonth",
    },
    initialDate: {
      type: String,
      default: new Date().toISOString().split('T')[0],
    },
    events: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    calendar = new Calendar(document.getElementById(this.id), {
      contentHeight: "auto",
      plugins: [dayGridPlugin],
      initialView: this.initialView,
      selectable: true,
      editable: false,
      events: this.events,
      initialDate: this.initialDate,
      locales: [itLocale, deLocale],//en è sempre supportato
      locale: this.$i18n.locale,
      firstDay: 1,
      headerToolbar: {
        start: "title", // will normally be on the left. if RTL, will be on the right
        center: "dayGridMonth,dayGridWeek",
        end: "today prev,next", // will normally be on the right. if RTL, will be on the left
      },
      views: {
        month: {
          titleFormat: {
            month: "long",
            year: "numeric",
          },
        },
        agendaWeek: {
          titleFormat: {
            month: "long",
            year: "numeric",
            day: "numeric",
          },
        },
        agendaDay: {
          titleFormat: {
            month: "short",
            year: "numeric",
            day: "numeric",
          },
        },
      },
      eventDidMount: info => {
        tippy([info.el], {
          content: info.event.extendedProps.prenotazione.nome_utente
              +' '+info.event.extendedProps.prenotazione.cognome_utente
              +'<br>'+info.event.extendedProps.prenotazione.email_utente
              +'<br>'+info.event.extendedProps.prenotazione.arrivo
              +'<br>'+info.event.title,
          allowHTML: true,
          followCursor: 'horizontal',
          plugins: [followCursor],
        });
      },
      eventClick: info => this.$router.push('/prenotazioni/'+info.event.extendedProps.prenotazione.id)
    });

    calendar.render();
  },
  beforeUnmount() {
    if (calendar) {
      calendar.destroy();
    }
  },
};
</script>
