<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-8">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Servizio Extra') }}</h5>
            </div>
            <div class="card-body">
              <div v-if="servizio_extra.id > 0 && servizio_extra.id_edificio > 0" class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Edificio') }}</h6>
                  <router-link :to="{name:'Edificio', params:{id: servizio_extra.id_edificio}}">
                    {{servizio_extra.nome_edificio}}
                  </router-link>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Nome') }}</h6>
                  <multilang-input
                      id="descrizione"
                      v-model:value-it="servizio_extra.descrizione"
                      v-model:value-en="servizio_extra.descrizione_en"
                      v-model:value-de="servizio_extra.descrizione_de"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Prezzo') }}</h6>
                  <argon-input v-model="servizio_extra.prezzo" type="number" min="0" step="0.01" required/>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Quantità Massima') }}</h6>
                  <argon-input v-model="servizio_extra.quantita_max" type="number" min="0" step="1" required/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Descrizione') }}</h6>
                  <multilang-textarea
                      id="descrizione_estesa"
                      v-model:value-it="servizio_extra.descrizione_estesa"
                      v-model:value-en="servizio_extra.descrizione_estesa_en"
                      v-model:value-de="servizio_extra.descrizione_estesa_de"
                  />
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4">
        <div class="card p-3 border-radius-xl bg-white h-100">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0">{{ $t('Foto') }}</h5>
          </div>
          <div class="card-body">
            <image-input :key="fotoKey" v-model="servizio_extra.foto_url"/>
            <p>{{ $t("Clicca sull'immagine per cambiarla") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import MultilangTextarea from "@/thecomponents/MultilangTextarea.vue";
import MultilangInput from "@/thecomponents/MultilangInput.vue";
import {savedModal, unsavedModal} from "@/modals";
import {mapState} from "vuex";
import ImageInput from "@/thecomponents/ImageInput.vue";

export default {
  name: "ServizioExtra",
  components: {ImageInput, MultilangInput, MultilangTextarea, ArgonButton, ArgonInput},
  data() {
    return {
      servizio_extra: {
        id: 0,
        id_edificio: 0,//inizializzato a ?eid= in created
        nome_edificio: '',
        descrizione: '',
        descrizione_en: '',
        descrizione_de: '',
        descrizione_estesa: '',
        descrizione_estesa_en: '',
        descrizione_estesa_de: '',
        prezzo: 0,
        quantita_max: 0,
        foto_url: ''
      },
      servizioExtraKey: Math.random(),
      fotoKey: Math.random(),
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    if (this.$route.query.eid) {
      this.servizio_extra.id_edificio = parseInt(this.$route.query.eid);
    }

    this.loadServizioExtra(this.$route.params.id);
  },
  methods: {
    loadServizioExtra(id) {
      this.servizio_extra.id = parseInt(id);
      if (this.servizio_extra.id !== 0) {
        this.apiClient.getServizioExtra(id).then(response => {
          if (response.data) {
            this.servizio_extra = response.data;
            this.servizioExtraKey = Math.random();
            this.fotoKey = Math.random();
          }
        });
      }
    },
    save() {
      if (!this.$refs.form.reportValidity()) {
        return;
      }
      this.apiClient.saveServizioExtra(this.servizio_extra).then(response => {
        if (response.data && response.data.success) {
          savedModal(this);
          this.$router.push({name: 'Edificio', params: {id: this.servizio_extra.id_edificio}});
        } else {
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
