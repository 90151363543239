<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Utenti")}}</h5>
          </div>
          <div class="card-body">
            <utenti-data-table :key="utentiKey" :utenti="utenti"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtentiDataTable from "@/parts/DataTables/UtentiDataTable.vue";

export default {
  name: "Utenti",
  components: {UtentiDataTable},
  data() {
    return {
      utenti: [],
      utentiKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getUtenti().then(response => {
      if(response.data){
        this.utenti = response.data;
        this.utentiKey = Math.random();
      }
    });
  },
};
</script>
