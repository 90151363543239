<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Utente') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Nome') }}</h6>
                  <p class="mb-0 text-sm">{{ utente['nome'] }}</p>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Cognome') }}</h6>
                  <p class="mb-0 text-sm">{{ utente['cognome'] }}</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Telefono') }}</h6>
                  <p class="mb-0 text-sm">{{ utente['telefono'] }}</p>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Email') }}</h6>
                  <p class="mb-0 text-sm"><a :href="'mailto:'+utente['email']">{{ utente['email'] }}</a></p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Telefono Verificato') }}</h6>
                  <yes-no-badge :proposition="utente.telefono_verificato"></yes-no-badge>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Documenti Verificati') }}</h6>
                  <yes-no-badge :proposition="utente.documenti_verificati"></yes-no-badge>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Prenotazioni")}}</h5>
            <div class="button-row d-flex">
              <router-link :to="{name:'NuovaPrenotazione', query:{id_utente: $route.params.id, id_prenotazione: 0}}" class="ms-auto">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                >{{$t('Nuova')}}</argon-button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <prenotazioni-data-table :key="prenotazioniKey" :prenotazioni="prenotazioni" :show-utente="false"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YesNoBadge from "@/thecomponents/YesNoBadge.vue";
import PrenotazioniDataTable from "@/parts/DataTables/PrenotazioniDataTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "Utente",
  components: {ArgonButton, PrenotazioniDataTable, YesNoBadge},
  data() {
    return {
      utente: {},
      prenotazioni: [],
      prenotazioniKey: Math.random()
    };
  },
  created() {
    this.loadUser(this.$route.params.id);
  },
  methods: {
    loadUser(id){
      this.apiClient.getUtente(id).then(response => {
        if(response.data){
          console.log(response.data);
          this.utente = response.data;
        }
      });
      this.apiClient.getPrenotazioniUtente(id).then(response => {
        if(response.data){
          this.prenotazioni = response.data;
          this.prenotazioniKey = Math.random();
        }
      });
    }
  }
};
</script>
