<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Data") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Descrizione") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Prezzo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Quantità") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Totale") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Tipo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Riferimento") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="s in altriPagamenti" :key="'altri-pagamenti-datatable-'+s.id">
        <td v-if="s.tipo === 'extra'">
          <strong>SRV{{ s.id }}</strong>
        </td>
        <td v-else>
          <strong>QRC{{ s.id }}</strong>
        </td>
        <td>{{ s.datetime }}</td>
        <td v-if="lang === 'it'">{{ s.descrizione }}</td>
        <td v-else-if="lang === 'en'">{{ s.descrizione_en }}</td>
        <td v-else-if="lang === 'de'">{{ s.descrizione_de }}</td>
        <td v-else></td>
        <td>{{ s.prezzo }} CHF</td>
        <td>{{ s.quantita }}</td>
        <td>{{ s.prezzo_pagato }} CHF</td>
        <td><altri-pagamenti-type-badge :type="s.tipo"/></td>
        <td v-if="s.tipo === 'extra'">
          <router-link :to="{name:'Prenotazione', params:{id: s.id_prenotazione}}">
            {{$t("Prenotazione")}} #{{s.id_prenotazione}}
          </router-link>
        </td>
        <td v-else>
          <router-link :to="{name:'Listino', params:{id: s.id_listino}}">
            {{$t("Listino")}} #{{s.id_listino}}
          </router-link>
        </td>
        <td v-if="s.tipo === 'extra'">
          <router-link :to="{name:'Prenotazione', params:{id: s.id_prenotazione}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
        </td>
        <td v-else>
          <router-link :to="{name:'Listino', params:{id: s.id_listino}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import {mapState} from "vuex";
import AltriPagamentiTypeBadge from "@/thecomponents/AltriPagamentiTypeBadge.vue";

export default {
  name: "AltriPagamentiDataTable",
  components: {
    AltriPagamentiTypeBadge,
    ArgonButton
  },
  props: {
    altriPagamenti: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
};
</script>
