export const savedModal = function(_this){
    _this.$swal({
        position: 'bottom-end',
        icon: 'success',
        title: _this.$t("Salvato"),
        showConfirmButton: false,
        backdrop: false,
        timer: 1500
    });
}

export const unsavedModal = function(_this){
    _this.$swal({
        icon: "error",
        text: _this.$t("Si è verificato un errore, riprovare."),
        customClass: {
            confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
    });
}

export const removeModal = function(_this, callback){
    _this.$swal({
        icon: "question",
        title: _this.$t("Eliminare?"),
        text: _this.$t("Non può essere annullato"),
        customClass: {
            confirmButton: "btn mb-0 btn-danger btn-sm me-1",
            cancelButton: "btn mb-0 btn-success btn-sm ms-1",
        },
        confirmButtonText: _this.$t("Elimina"),
        cancelButtonText: _this.$t("Annulla"),
        showCancelButton: true,
        buttonsStyling: false,
    }).then((result) => {
        if(result.isConfirmed && callback){
            callback();
        }
    });
}
