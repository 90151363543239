<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Sconti")}}</h5>
            <div class="button-row d-flex">
              <router-link :to="{name:'Sconto', params:{id: 0}}" class="ms-auto">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                >{{$t('Nuovo')}}</argon-button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <sconti-data-table :key="scontiKey" :sconti="sconti"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScontiDataTable from "@/parts/DataTables/ScontiDataTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "Sconti",
  components: {ArgonButton, ScontiDataTable},
  data() {
    return {
      sconti: [],
      scontiKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getSconti().then(response => {
      if(response.data){
        this.sconti = response.data;
        this.scontiKey = Math.random();
      }
    });
  },
};
</script>
