import axios from 'axios';

const axiosClient = axios.create({
  baseURL: 'https://api.the-co.ch',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.setAuthorizationToken = function(token) {
  if(token){
    this.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }else{
    delete this.defaults.headers.common['Authorization'];
  }
};

export default {
  setAuthorizationToken: (token) => axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`,

  login: (email, password) => {
    return axiosClient.post('/v2/?action=admin_login', {
      email: email,
      password: password,
    });
  },

  getAgenzie: () => {
    return axiosClient.get('/v2/?action=admin_get_agenzie');
  },

  getAgenzia: (id) => {
    return axiosClient.get('/v2/?action=admin_get_agenzia&id='+id);
  },

  saveAgenzia: (agenzia) => {
    return axiosClient.post('/v2/?action=admin_save_agenzia', agenzia);
  },

  removeAgenzia: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_agenzia&id='+id);
  },

  getUtenti: () => {
    return axiosClient.get('/v2/?action=admin_get_utenti');
  },

  getUtente: (id) => {
    return axiosClient.get('/v2/?action=admin_get_utente&id='+id);
  },

  getEdifici: () => {
    return axiosClient.get('/v2/?action=admin_get_edifici');
  },

  getEdificio: (id) => {
    return axiosClient.get('/v2/?action=admin_get_edificio&id='+id);
  },

  saveEdificio: (edificio) => {
    return axiosClient.post('/v2/?action=admin_save_edificio', edificio);
  },

  removeEdificio: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_edificio&id='+id);
  },

  getSpazi: (id_edificio) => {
    return axiosClient.get('/v2/?action=admin_get_spazi&id_edificio='+id_edificio);
  },

  getSpazio: (id) => {
    return axiosClient.get('/v2/?action=admin_get_spazio&id='+id);
  },

  saveSpazio: (spazio) => {
    return axiosClient.post('/v2/?action=admin_save_spazio', spazio);
  },

  removeSpazio: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_spazio&id='+id);
  },

  getServizi: (id_servizio) => {
    return axiosClient.get('/v2/?action=admin_get_servizi&id_spazio='+id_servizio);
  },

  getServizio: (id) => {
    return axiosClient.get('/v2/?action=admin_get_servizio&id='+id);
  },

  saveServizio: (servizio) => {
    return axiosClient.post('/v2/?action=admin_save_servizio', servizio);
  },

  removeServizio: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_servizio&id='+id);
  },

  getSconti: () => {
    return axiosClient.get('/v2/?action=admin_get_sconti');
  },

  getSconto: (id) => {
    return axiosClient.get('/v2/?action=admin_get_sconto&id='+id);
  },

  saveSconto: (sconto) => {
    return axiosClient.post('/v2/?action=admin_save_sconto', sconto);
  },

  removeSconto: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_sconto&id='+id);
  },

  getPrenotazioni: () => {
    return axiosClient.get('/v2/?action=admin_get_prenotazioni');
  },

  getPrenotazioniConfermate: () => {
    return axiosClient.get('/v2/?action=admin_get_prenotazioni&completed=true');
  },

  getPrenotazioniUtente: (id_utente) => {
    return axiosClient.get('/v2/?action=admin_get_prenotazioni_utente&id_utente='+id_utente);
  },

  getPrenotazione: (id) => {
    return axiosClient.get('/v2/?action=admin_get_prenotazione&id='+id);
  },

  savePrenotazione: (id_utente, id, prenotazione, servizi) => {
    return axiosClient.post('/v2/?action=admin_save_prenotazione', {
      id_utente: id_utente,
      id: id,
      prenotazione: prenotazione,
      servizi: servizi
    });
  },

  removePrenotazione: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_prenotazione&id='+id);
  },

  getPagamenti: () => {
    return axiosClient.get('/v2/?action=admin_get_pagamenti');
  },

  getPagamentiPrenotazione: (id_prenotazione) => {
    return axiosClient.get('/v2/?action=admin_get_pagamenti_prenotazione&id_prenotazione='+id_prenotazione);
  },

  getPagamento: (id) => {
    return axiosClient.get('/v2/?action=admin_get_pagamento&id='+id);
  },

  savePagamento: (pagamento) => {
    return axiosClient.post('/v2/?action=admin_save_pagamento', pagamento);
  },

  removePagamento: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_pagamento&id='+id);
  },

  getStatIncasso: () => {
    return axiosClient.get('/v2/?action=admin_stat_incasso');
  },

  getStatPrenotazioni: () => {
    return axiosClient.get('/v2/?action=admin_stat_prenotazioni');
  },

  getStatPermanenzaMedia: () => {
    return axiosClient.get('/v2/?action=admin_stat_permanenza_media');
  },

  getStatPercentualeOccupazione: () => {
    return axiosClient.get('/v2/?action=admin_stat_percentuale_occupazione');
  },

  getListaSpazi: (lang) => {
    return axiosClient.get('/v2/?action=admin_get_lista_spazi&lang='+lang);
  },

  calcoloPrezzo: (lang, id_vecchia_prenotazione, inizio, fine, spazio, tipo_pagamento, servizi) => {
    return axiosClient.get('/v2/?action=calcolo_prezzo&lang='+lang+"&id_vecchia_prenotazione="+id_vecchia_prenotazione+"&datetime_inizio="+inizio+"&datetime_fine="+fine+"&id_spazio="+spazio+"&tipo_pagamento="+tipo_pagamento+servizi);
  },

  getProssimePrenotazioni: () => {
    return axiosClient.get('/v2/?action=admin_get_prossime_prenotazioni');
  },

  getPrenotazioniSpazio: (id_spazio, exclude_canceled=false) => {
    return axiosClient.get('/v2/?action=admin_get_prenotazioni_spazio&id_spazio='+id_spazio+"&exclude_canceled="+exclude_canceled);
  },

  getServizioExtra: (id) => {
    return axiosClient.get('/v2/?action=admin_get_servizio_extra&id='+id);
  },

  getServiziExtra: (id_edificio) => {
    return axiosClient.get('/v2/?action=admin_get_servizi_extra&id_edificio='+id_edificio);
  },

  saveServizioExtra: (servizio_extra) => {
    return axiosClient.post('/v2/?action=admin_save_servizio_extra', servizio_extra);
  },

  removeServizioExtra: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_servizio_extra&id='+id);
  },

  getAltriPagamenti: () => {
    return axiosClient.get('/v2/?action=admin_get_altri_pagamenti');
  },

  getServizioListino: (id) => {
    return axiosClient.get('/v2/?action=admin_get_servizio_listino&id='+id);
  },

  getServiziListino: (id_listino) => {
    return axiosClient.get('/v2/?action=admin_get_servizi_listino&id_listino='+id_listino);
  },

  saveServizioListino: (servizio_listino) => {
    return axiosClient.post('/v2/?action=admin_save_servizio_listino', servizio_listino);
  },

  removeServizioListino: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_servizio_listino&id='+id);
  },

  getListini: () => {
    return axiosClient.get('/v2/?action=admin_get_listini');
  },

  getListino: (id) => {
    return axiosClient.get('/v2/?action=admin_get_listino&id='+id);
  },

  saveListino: (listino) => {
    return axiosClient.post('/v2/?action=admin_save_listino', listino);
  },

  removeListino: (id) => {
    return axiosClient.get('/v2/?action=admin_remove_listino&id='+id);
  },
};
