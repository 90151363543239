<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Spazio") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Edificio") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="s in spazi" :key="'calendario-datatable-'+s.id">
        <td><strong>{{ s.id }}</strong></td>
        <td v-if="lang === 'it'">{{ s.nome }}</td>
        <td v-else-if="lang === 'en'">{{ s.nome_en }}</td>
        <td v-else-if="lang === 'de'">{{ s.nome_de }}</td>
        <td v-else></td>
        <td>{{ s.nome_edificio }}</td>
        <td>
          <router-link :to="{name:'Calendario', params:{id: s.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import {mapState} from "vuex";

export default {
  name: "CalendariDataTable",
  components: {
    ArgonButton
  },
  props: {
    spazi: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  }
};
</script>
