<template>
  <div class="image-input">
    <div
        class="drop-area"
        :class="{ 'drag-over': isDragging, 'has-image': !!previewUrl }"
        @dragover.prevent="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
        @click="handleClick"
    >
      <div v-if="previewUrl" class="image-preview">
        <img :src="previewUrl" alt="Preview" />
      </div>
      <span v-else>{{$t("Trascina un'immagine qui o clicca per aprirne una")}}</span>
      <input ref="fileInput" type="file" @change="handleFileChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageInput",
  props: {
    modelValue: {
      type: String,
      required: true,
      default: ""
    },
    maxWidth: {
      type: Number,
      default: 800
    },
    maxHeight: {
      type: Number,
      default: 800
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      previewUrl: this.modelValue,
      isDragging: false
    };
  },
  methods: {
    handleClick(){
      this.$refs.fileInput.click();
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      this.loadAndProcessImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event) {
      this.loadAndProcessImage(event.target.files[0]);
    },
    async loadAndProcessImage(file) {
      if(!file){
        return;
      }

      const imageUrl = URL.createObjectURL(file);

      const img = new Image();
      img.src = imageUrl;

      img.onload = async () => {
        const canvas = document.createElement("canvas");
        const maxW = this.maxWidth;
        const maxH = this.maxHeight;

        let newW = img.width;
        let newH = img.height;

        if(img.width > maxW){
          newW = maxW;
          newH = (img.height * maxW) / img.width;
        }

        if(newH > maxH){
          newH = maxH;
          newW = (newW * maxH) / newH;
        }

        canvas.width = newW;
        canvas.height = newH;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newW, newH);

        let dataUrl = canvas.toDataURL("image/webp", 0.75);
        /* Safari non supporta la compressione webp ad oggi.
         * Secondo MDN toDataURL fa un default a image/png se l'immagine non è supportata.
         * Per questo motivo è sufficiente fare un fallback a jpeg se il mime type del file originale è diverso da png.
         * Se il file originale fosse stato già una png, quindi possibilmente con trasparenza, non serve fare nulla.
         */
        if(dataUrl.indexOf("data:image/webp") !== 0 && file.type !== "image/png"){
          dataUrl = canvas.toDataURL("image/jpeg", 0.75);
        }

        this.previewUrl = dataUrl;
        this.$emit("update:modelValue", dataUrl);
      };
    }
  }
};
</script>

<style scoped>
input[type="file"]{
  display: none;
}

.image-input {
  width: 100%;
  margin: auto;
  text-align: center;
}

.image-preview {
  margin-bottom: 1rem;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 1rem;
  cursor: pointer;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-area.has-image {
  border: none;
  padding: 0;
}

.drag-over {
  border-color: #555;
}

img {
  max-width: 100%;
  width: 100%;
}
</style>
