import { createStore } from "vuex";
import apiClient from "../api-client";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    isLoggedIn: !!localStorage.getItem('bearer-token') && !!localStorage.getItem('role'),
    bearerToken: localStorage.getItem('bearer-token'),
    userRole: localStorage.getItem('role'),
    lang: process.env.VUE_APP_DEFAULT_LANG
  },
  mutations: {
    /*
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    */
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      }
    },
    /*
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    */
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    setLoginData(state, data) {
      if(data.token){
        this.state.isLoggedIn = true;
        this.state.bearerToken = data.token;
        this.state.userRole = data.role;
        localStorage.setItem('bearer-token', data.token);
        localStorage.setItem('role', data.role);
        this.state.lang = data.lang;
        localStorage.setItem('lang', data.lang);
        apiClient.setAuthorizationToken(data.token);
      }else{
        this.logout();
      }
    },
    logout() {
      this.state.isLoggedIn = false;
      this.state.bearerToken = undefined;
      this.state.userRole = undefined;
      localStorage.clear();
      apiClient.setAuthorizationToken(false);
      //location.href="/";
    },
    setLanguage(state, lang){
      this.state.lang = lang;
      localStorage.setItem('lang', lang);
      location.reload();
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    }
  },
  getters: {}
});
