<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Prenotazioni")}}</h5>
          </div>
          <div class="card-body">
            <prenotazioni-data-table :key="prenotazioniKey" :prenotazioni="prenotazioni"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrenotazioniDataTable from "@/parts/DataTables/PrenotazioniDataTable.vue";

export default {
  name: "Prenotazioni",
  components: {PrenotazioniDataTable},
  data() {
    return {
      prenotazioni: [],
      prenotazioniKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getPrenotazioni().then(response => {
      if(response.data){
        this.prenotazioni = response.data;
        this.prenotazioniKey = Math.random();
      }
    });
  },
};
</script>
