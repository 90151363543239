<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Dati di Contatto")}}</h5>
            <div class="button-row d-flex">
              <router-link :to="{name:'Agenzia', params:{id: 0}}" class="ms-auto">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                >{{$t('Nuovo')}}</argon-button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <agenzie-data-table :key="agenzieKey" :agenzie="agenzie"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgenzieDataTable from "@/parts/DataTables/AgenzieDataTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "Agenzie",
  components: {ArgonButton, AgenzieDataTable},
  data() {
    return {
      agenzie: [],
      agenzieKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getAgenzie().then(response => {
      if(response.data){
        this.agenzie = response.data;
        this.agenzieKey = Math.random();
      }
    });
  },
};
</script>
