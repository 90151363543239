<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Dati di Contatto') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Nome') }}</h6>
                  <argon-input
                      id="nome"
                      v-model="agenzia.nome"
                      required
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Indirizzo') }}</h6>
                  <argon-input
                      id="indirizzo"
                      v-model="agenzia.indirizzo"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Email') }}</h6>
                  <argon-input
                      id="email"
                      v-model="agenzia.email"
                      type="email"
                      required
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Telefono') }}</h6>
                  <argon-input
                      id="email"
                      v-model="agenzia.telefono"
                      type="tel"
                      required
                  />
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {savedModal, unsavedModal} from "@/modals";

export default {
  name: "Agenzia",
  components: {ArgonButton, ArgonInput},
  data() {
    return {
      agenzia: {
        id: 0,
        nome: "",
        indirizzo: "",
        email: "",
        telefono: ""
      }
    };
  },
  created() {
    this.loadAgenzia(this.$route.params.id);
  },
  methods: {
    loadAgenzia(id){
      this.agenzia.id = parseInt(id);
      if(this.agenzia.id !== 0) {
        this.apiClient.getAgenzia(id).then(response => {
          if (response.data) {
            console.log(response.data);
            this.agenzia = response.data;
          }
        });
      }
    },
    save(){
      if(!this.$refs.form.reportValidity()){
        return;
      }
      this.apiClient.saveAgenzia(this.agenzia).then(response => {
        if(response.data && response.data.success){
          savedModal(this);
          this.$router.push({name: 'Agenzie'});
        }else{
          unsavedModal(this);
        }
      });
    }
  }
};
</script>
