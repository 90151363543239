<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Calendari")}}</h5>
          </div>
          <div class="card-body">
            <calendari-data-table :key="spaziKey" :spazi="spazi"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendariDataTable from "@/parts/DataTables/CalendariDataTable.vue";

export default {
  name: "Calendari",
  components: {CalendariDataTable},
  data() {
    return {
      spazi: [],
      spaziKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getListaSpazi(this.$i18n.locale).then(response => {
      if(response.data){
        this.spazi = response.data;
        this.spaziKey = Math.random();
      }
    });
  },
};
</script>
