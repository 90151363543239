<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Prenotazione') }}</h5>
              <div class="button-row d-flex">
                <router-link :to="{name:'NuovaPrenotazione', query:{id_utente: prenotazione.id_utente, id_prenotazione: $route.params.id}}" class="ms-auto">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                  >{{$t('Modifica')}}</argon-button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Arrivo') }}</h6>
                  <p class="mb-0 text-sm">{{ prenotazione.arrivo }}</p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Partenza') }}</h6>
                  <p class="mb-0 text-sm">{{ prenotazione.partenza }}</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Edificio') }}</h6>
                  <p class="mb-0 text-sm">
                    <router-link :to="{name:'Edificio', params:{id: prenotazione.id_edificio}}">
                      {{ prenotazione.nome_edificio }}
                    </router-link>
                  </p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Spazio') }}</h6>
                  <p class="mb-0 text-sm">
                    <router-link :to="{name:'Spazio', params:{id: prenotazione.id_spazio}}">
                      <span v-if="$i18n.locale==='it'">{{ prenotazione.nome }}</span>
                      <span v-if="$i18n.locale==='en'">{{ prenotazione.nome_en }}</span>
                      <span v-if="$i18n.locale==='de'">{{prenotazione.nome_de}}</span>
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Utente') }}</h6>
                  <p class="mb-0 text-sm">
                    <router-link :to="{name:'Utente', params:{id: prenotazione.id_utente}}">
                      {{ prenotazione.nome_utente }} {{ prenotazione.cognome_utente }}
                    </router-link>
                  </p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Email') }}</h6>
                  <p class="mb-0 text-sm"><a :href="'mailto:'+prenotazione['email_utente']">{{ prenotazione['email_utente'] }}</a></p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Tipo Pagamento') }}</h6>
                  <pagamento-type-badge :type="prenotazione.tipo_pagamento"></pagamento-type-badge>
                </div>
                <div class="col-12 col-sm-6">
                  <h6>{{ $t('Stato Prenotazione') }}</h6>
                  <pagamento-status-badge :type="prenotazione.payment_status"></pagamento-status-badge>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <h6>{{ $t('Totale') }}</h6>
                  <p v-if="prenotazione.rate > 0">CHF {{Number.parseFloat(prenotazione.acconto).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('acconto')}}</p>
                  <p v-if="prenotazione.rate > 0">CHF {{Number.parseFloat(prenotazione.rata).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} x {{prenotazione.rate}} {{$t('rate')}}</p>
                  <p v-if="prenotazione.rate > 0">CHF {{Number.parseFloat(prenotazione.rata_finale).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('rata finale')}}</p>
                  <p>CHF {{Number.parseFloat(prenotazione.prezzo).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}}</p>
                </div>
                <div class="col-6">
                  <h6>{{ $t('Rimanenza') }}</h6>
                  <p>CHF {{Number.parseFloat(prenotazione.saldati).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('saldati')}}</p>
                  <p>CHF {{Number.parseFloat(prenotazione.rimanenti).toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, style: 'decimal'})}} {{$t('da saldare')}}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Pagamenti")}}</h5>
            <div class="button-row d-flex">
              <router-link :to="{name:'Pagamento', params:{id: 0}, query:{id_prenotazione: prenotazione.id}}" class="ms-auto">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                >{{$t('Nuovo')}}</argon-button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <pagamenti-data-table :key="pagamentiKey" :pagamenti="pagamenti" :show-prenotazione="false" :show-utente="false"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Servizi Extra Pagati")}}</h5>
          </div>
          <div class="card-body">
            <servizi-extra-prenotati-data-table :key="serviziExtraKey" :servizi_extra_prenotati="prenotazione.servizi_extra_prenotati"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagamentiDataTable from "@/parts/DataTables/PagamentiDataTable.vue";
import PagamentoStatusBadge from "@/thecomponents/PagamentoStatusBadge.vue";
import PagamentoTypeBadge from "@/thecomponents/PagamentoTypeBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ServiziExtraPrenotatiDataTable from "@/parts/DataTables/ServiziExtraPrenotatiDataTable.vue"

export default {
  name: "Prenotazione",
  components: {ArgonButton, PagamentoTypeBadge, PagamentoStatusBadge, PagamentiDataTable, ServiziExtraPrenotatiDataTable},
  data() {
    return {
      prenotazione: {},
      pagamenti: [],
      pagamentiKey: Math.random(),
      serviziExtraKey: Math.random()
    };
  },
  created() {
    this.loadPrenotazione(this.$route.params.id);
  },
  methods: {
    loadPrenotazione(id){
      this.apiClient.getPrenotazione(id).then(response => {
        if(response.data){
          this.prenotazione = response.data;
          this.serviziExtraKey = Math.random();
        }
      });
      this.apiClient.getPagamentiPrenotazione(id).then(response => {
        if(response.data){
          console.log(response.data);
          this.pagamenti = response.data;
          this.pagamentiKey = Math.random();
        }
      });
    }
  }
};
</script>
