<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="$t('Incassato')"
              :value="'CHF '+incassoCurrent"
              :value-subtitle="$t('negli ultimi 30 giorni')"
              :previous="'CHF '+incassoPrevious"
              :previous-subtitle="$t('nei 30 giorni precedenti')"
              :description="`<span class='text-sm font-weight-bolder text-`+(incassoPercentage>=0?'success':'danger')+`'>`+(incassoPercentage>=0?'🠙':'🠛')+incassoPercentage+'%</span>'"
              :icon="{
                component: 'fa-solid fa-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="$t('Prenotazioni')"
              :value="prenotazioniCurrent"
              :value-subtitle="$t('negli ultimi 30 giorni')"
              :previous="prenotazioniPrevious"
              :previous-subtitle="$t('nei 30 giorni precedenti')"
              :description="`<span class='text-sm font-weight-bolder text-`+(prenotazioniPercentage>=0?'success':'danger')+`'>`+(prenotazioniPercentage>=0?'🠙':'🠛')+prenotazioniPercentage+'%</span>'"
              :icon="{
                component: 'fa-solid fa-user',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                :title="$t('Permanenza Media')"
                :value="permanenzaMediaCurrent"
                :value-subtitle="$t('negli ultimi 30 giorni')"
                :previous="permanenzaMediaPrevious"
                :previous-subtitle="$t('nei 30 giorni precedenti')"
                :description="`<span class='text-sm font-weight-bolder text-`+(permanenzaMediaPercentage>=0?'success':'danger')+`'>`+(permanenzaMediaPercentage>=0?'🠙':'🠛')+permanenzaMediaPercentage+'%</span>'"
                :icon="{
                component: 'fa-solid fa-house',
                background: 'bg-gradient-success',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                :title="$t('Percentuale Occupazione')"
                :value="percentualeOccupazioneCurrent"
                :value-subtitle="$t('negli ultimi 30 giorni')"
                :previous="percentualeOccupazionePrevious"
                :previous-subtitle="$t('nei 30 giorni precedenti')"
                :description="`<span class='text-sm font-weight-bolder text-`+(percentualeOccupazionePercentage>=0?'success':'danger')+`'>`+(percentualeOccupazionePercentage>=0?'🠙':'🠛')+percentualeOccupazionePercentage+'%</span>'"
                :icon="{
                component: 'fa-solid fa-percentage',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle'
              }"
            />
          </div>
        </div>
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-header">
            <h5 class="font-weight-bolder mb-0mb-0">{{ $t("Prossime Prenotazioni")}}</h5>
          </div>
          <div class="card-body">
            <prenotazioni-data-table :key="prenotazioniKey" :prenotazioni="prenotazioni"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import PrenotazioniDataTable from "@/parts/DataTables/PrenotazioniDataTable.vue";

export default {
  name: "Dashboard",
  components: {
    MiniStatisticsCard,
    PrenotazioniDataTable
  },
  data() {
    return {
      incassoCurrent: 0,
      incassoPrevious: 0,
      incassoPercentage: 0,
      prenotazioniCurrent: 0,
      prenotazioniPrevious: 0,
      prenotazioniPercentage: 0,
      permanenzaMediaCurrent: 0,
      permanenzaMediaPrevious: 0,
      permanenzaMediaPercentage: 0,
      percentualeOccupazioneCurrent: 0,
      percentualeOccupazionePrevious: 0,
      percentualeOccupazionePercentage: 0,
      prenotazioni: [],
      prenotazioniKey: Math.random()
    };
  },
  mounted() {
    this.apiClient.getStatIncasso().then(response => {
      if(response.data){
        console.log(response.data);
        this.incassoCurrent = response.data.currentMonth;
        this.incassoPrevious = response.data.previousMonth;
        this.incassoPercentage = response.data.increase;
      }
    });
    this.apiClient.getStatPrenotazioni().then(response => {
      if(response.data){
        console.log(response.data);
        this.prenotazioniCurrent = response.data.currentMonth;
        this.prenotazioniPrevious = response.data.previousMonth;
        this.prenotazioniPercentage = response.data.increase;
      }
    });
    this.apiClient.getStatPermanenzaMedia().then(response => {
      if(response.data){
        console.log(response.data);
        this.permanenzaMediaCurrent = response.data.currentMonth;
        this.permanenzaMediaPrevious = response.data.previousMonth;
        this.permanenzaMediaPercentage = response.data.increase;
      }
    });
    this.apiClient.getStatPercentualeOccupazione().then(response => {
      if(response.data){
        console.log(response.data);
        this.percentualeOccupazioneCurrent = response.data.currentMonth+"%";
        this.percentualeOccupazionePrevious = response.data.previousMonth+"%";
        this.percentualeOccupazionePercentage = response.data.increase;
      }
    });
    this.apiClient.getProssimePrenotazioni().then(response => {
      if(response.data){
        this.prenotazioni = response.data;
        this.prenotazioniKey = Math.random();
      }
    });
  },
};
</script>
