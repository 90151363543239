import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import Dashboard from "@/views/Dashboard.vue";
import Signin from "@/views/Signin.vue";
import Utenti from "@/views/Utenti.vue";
import Utente from "@/views/Utente.vue";

import Edifici from "@/views/Edifici.vue";
import Edificio from "@/views/Edificio.vue";
import Spazio from "@/views/Spazio.vue";
import Servizio from "@/views/Servizio.vue";
import Agenzie from "@/views/Agenzie.vue";
import Agenzia from "@/views/Agenzia.vue";
import Sconti from "@/views/Sconti.vue";
import Sconto from "@/views/Sconto.vue";
import Pagamenti from "@/views/Pagamenti.vue";
import Pagamento from "@/views/Pagamento.vue";
import Prenotazioni from "@/views/Prenotazioni.vue";
import Prenotazione from "@/views/Prenotazione.vue";
import NuovaPrenotazione from "@/views/NuovaPrenotazione.vue";
import Calendari from "@/views/Calendari.vue";
import Calendario from "@/views/Calendario.vue";
import ServizioExtra from "@/views/ServizioExtra.vue";
import AltriPagamenti from "@/views/AltriPagamenti.vue";
import Listini from "@/views/Listini.vue";
import Listino from "@/views/Listino.vue";
import ServizioListino from "@/views/ServizioListino.vue";

const routes = [
  {
    path: "/",
    name: "Signin",
    component: Signin,
    meta: {
      allowAnonymous: true,
      isSignin: true
    }
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/prenotazioni/",
    name: "Prenotazioni",
    component: Prenotazioni
  },
  {
    path: "/prenotazioni/:id",
    name: "Prenotazione",
    component: Prenotazione
  },
  {
    path: "/nuova-prenotazione/",
    name: "NuovaPrenotazione",
    component: NuovaPrenotazione
  },
  {
    path: "/utenti/",
    name: "Utenti",
    component: Utenti,
    meta: {
      requiredRole: 10
    }
  },
  {
    path: "/utenti/:id",
    name: "Utente",
    component: Utente,
  },
  {
    path: "/sconti/",
    name: "Sconti",
    component: Sconti
  },
  {
    path: "/sconti/:id",
    name: "Sconto",
    component: Sconto,
  },
  {
    path: "/edifici/",
    name: "Edifici",
    component: Edifici
  },
  {
    path: "/edifici/:id",
    name: "Edificio",
    component: Edificio
  },
  {
    path: "/spazi/:id",
    name: "Spazio",
    component: Spazio
  },
  {
    path: "/servizi/:id",
    name: "Servizio",
    component: Servizio
  },
  {
    path: "/contatti/",
    name: "Agenzie",
    component: Agenzie
  },
  {
    path: "/contatti/:id",
    name: "Agenzia",
    component: Agenzia
  },
  {
    path: "/pagamenti",
    name: "Pagamenti",
    component: Pagamenti
  },
  {
    path: "/pagamenti/:id",
    name: "Pagamento",
    component: Pagamento
  },
  {
    path: "/calendari",
    name: "Calendari",
    component: Calendari
  },
  {
    path: "/calendari/:id",
    name: "Calendario",
    component: Calendario
  },
  {
    path: "/servizio-extra/:id",
    name: "ServizioExtra",
    component: ServizioExtra
  },
  {
    path: "/altri-pagamenti/",
    name: "AltriPagamenti",
    component: AltriPagamenti
  },
  {
    path: "/listini/",
    name: "Listini",
    component: Listini
  },
  {
    path: "/listino/:id",
    name: "Listino",
    component: Listino
  },
  {
    path: "/servizio-listino/:id",
    name: "ServizioListino",
    component: ServizioListino
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  console.log(to);

  const userRole = parseInt(localStorage.getItem("role")) || 0;

  if(to.matched.some(record => record.meta.requiredRole && record.meta.requiredRole > userRole)){
    console.log("Access Denied");
    next({ name: 'Dashboard' });
    return;
  }

  if(to.matched.some(record => record.meta.allowAnonymous)){
    // this route does not require auth, check if logged in.
    // if so, redirect to dashboard.
    if(to.meta.isSignin && store.state.isLoggedIn){
      console.log('route A');
      next({ name: 'Dashboard' });
    }else{
      console.log('route B');
      next();
    }
  }else{
    // this route requires auth, check if logged in.
    // if not, redirect to login page.
    if(!store.state.isLoggedIn){
      console.log('route C');
      next({ name: 'Signin' });
    }else{
      console.log('route D');
      next();
    }
  }
})

export default router;
