<template>
  <div class="table-responsive">
    <table ref="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Data") }}
        </th>
        <th v-if="showPrenotazione" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Prenotazione") }}
        </th>
        <th v-if="showUtente" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Utente") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Mezzo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Importo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Stato") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Rata?") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="p in pagamenti" :key="'pagamento-datatable-'+p.id">
        <td><strong>{{ p.id }}</strong></td>
        <td>{{ p.datetime }}</td>
        <td v-if="showPrenotazione">
          <router-link :to="{name:'Prenotazione', params:{id: p.id_prenotazione}}">
          # {{ p.id_prenotazione }}
          </router-link>
          <br>
          <router-link :to="{name:'Spazio', params:{id: p.id_spazio}}">
            <span v-if="lang === 'it'">{{ p.nome }}</span>
            <span v-else-if="lang === 'en'">{{ p.nome_en }}</span>
            <span v-else-if="lang === 'de'">{{ p.nome_de }}</span>
            <span v-else></span>
          </router-link>
          <br>
          <router-link :to="{name:'Edificio', params:{id: p.id_edificio}}">
            {{ p.nome_edificio }}
          </router-link>
        </td>
        <td v-if="showUtente">
          <router-link :to="{name:'Utente', params:{id: p.id_utente}}">
            {{p.nome_utente}} {{p.cognome_utente}}<br>
          </router-link>
          <a :href="'mailto:'+p.email_utente">{{p.email_utente}}</a>
        </td>
        <td><pagamento-type-badge :type="p.mezzo"/></td>
        <td>{{ p.importo }} CHF</td>
        <td><pagamento-status-badge :type="p.payment_status"/></td>
        <td><yes-no-badge :proposition="p.is_rata" yes-style="primary" no-style="primary"/></td>
        <td>
          <!--router-link :to="{name:'Pagamento', params:{id: p.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link-->
          <a :href="'/pagamenti/'+p.id">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </a>
          <!--argon-button class="ms-1" size="sm" color="danger" @click.prevent="remove(p.id)"><span class="fa fa-trash-can"></span></argon-button-->
        </td>
      </tr>
      </tbody>
    </table>
</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import {mapState} from "vuex";
import PagamentoTypeBadge from "@/thecomponents/PagamentoTypeBadge.vue";
import YesNoBadge from "@/thecomponents/YesNoBadge.vue";
import PagamentoStatusBadge from "@/thecomponents/PagamentoStatusBadge.vue";
import {removeModal} from "@/modals";

export default {
  name: "PagamentiDataTable",
  components: {
    PagamentoStatusBadge,
    YesNoBadge,
    PagamentoTypeBadge,
    ArgonButton
  },
  props: {
    pagamenti: {
      type: Array,
      default: () => []
    },
    showPrenotazione: {
      type: Boolean,
      default: true
    },
    showUtente: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable(this.$refs["datatable-basic"], {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
  methods: {
    remove: function(id){
      removeModal(this, () => {
        this.apiClient.removePagamento(id).then(() => {
          location.reload();
        });
      });
    }
  }
};
</script>
