<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Nome") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Cognome") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Telefono") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Email") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Verificato") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Documenti") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Ruolo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="u in utenti" :key="'utente-datatable-'+u.id">
        <td><strong>{{ u.id }}</strong></td>
        <td>{{ u.nome }}</td>
        <td>{{ u.cognome }}</td>
        <td>{{ u.telefono }}</td>
        <td><a :href="'mailto:'+u.email">{{ u.email }}</a></td>
        <td>
          <yes-no-badge :proposition="u.telefono_verificato"></yes-no-badge>
        </td>
        <td>
          <yes-no-badge :proposition="u.documenti_verificati"></yes-no-badge>
        </td>
        <td>
          <user-role-badge :role="u.ruolo"></user-role-badge>
        </td>
        <td>
          <router-link :to="{name:'Utente', params:{id: u.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import YesNoBadge from "@/thecomponents/YesNoBadge.vue";
import UserRoleBadge from "@/thecomponents/UserRoleBadge.vue";

export default {
  name: "UtentiDataTable",
  components: {
    UserRoleBadge,
    YesNoBadge,
    ArgonButton
  },
  props: {
    utenti: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
};
</script>
