<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Tipo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Nome") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Indirizzo") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="e in edifici" :key="'edificio-datatable-'+e.id">
        <td><strong>{{ e.id }}</strong></td>
        <td><edificio-type-badge :type="e.type"></edificio-type-badge></td>
        <td>{{ e.nome }}</td>
        <td>{{ e.indirizzo }}</td>
        <td>
          <router-link :to="{name:'Edificio', params:{id: e.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
          <argon-button class="ms-1" size="sm" color="danger" @click.prevent="remove(e.id)"><span class="fa fa-trash-can"></span></argon-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import EdificioTypeBadge from "@/thecomponents/EdificioTypeBadge.vue";
import {removeModal} from "@/modals";

export default {
  name: "EdificiDataTable",
  components: {
    EdificioTypeBadge,
    ArgonButton
  },
  props: {
    edifici: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
  methods: {
    remove: function(id){
      removeModal(this, () => {
        this.apiClient.removeEdificio(id).then(() => {
          location.reload();
        });
      });
    }
  }
};
</script>
