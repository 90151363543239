<template>
  <argon-badge v-if="type === 'dormakaba'" size="sm" color="primary">{{$t('Dormakaba')}}</argon-badge>
  <argon-badge v-else-if="type === 'chiave'" size="sm" color="primary">{{$t('Chiave')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Sconosciuta')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "SerraturaTypeBadge",
  components: {ArgonBadge},
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
