<template>
  <div class="table-responsive">
    <table id="datatable-basic" class="table table-flush">
      <thead class="thead-light">
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          #
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Codice") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Ammontare") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ $t("Tipologia") }}
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" data-sortable="false">
          {{ $t("Azioni") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="s in sconti" :key="'sconto-datatable-'+s.id">
        <td><strong>{{ s.id }}</strong></td>
        <td>{{ s.codice }}</td>
        <td>
          {{ s.ammontare }}
          <span v-if="s.tipologia === 'percentuale'">%</span>
          <span v-else-if="s.tipologia === 'fisso'">CHF</span>
        </td>
        <td>
          <sconti-type-badge :type="s.tipologia"/>
        </td>
        <td>
          <router-link :to="{name:'Sconto', params:{id: s.id}}">
            <argon-button size="sm">{{$t('Vedi')}}</argon-button>
          </router-link>
          <argon-button class="ms-1" size="sm" color="danger" @click.prevent="remove(s.id)"><span class="fa fa-trash-can"></span></argon-button>
        </td>
      </tr>
      </tbody>
    </table>
</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ScontiTypeBadge from "@/thecomponents/ScontiTypeBadge.vue";
import {removeModal} from "@/modals";

export default {
  name: "ScontiDataTable",
  components: {
    ScontiTypeBadge,
    ArgonButton
  },
  props: {
    sconti: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new DataTable("#datatable-basic", {
      searchable: true,
      fixedHeight: true,
      paging: false,
      labels: {
        placeholder: this.$t("Cerca..."),
        searchTitle: this.$t("Cerca nella tabella"),
        perPage: this.$t("elementi per pagina"),
        pageTitle: this.$t("Pagina page").replaceAll('[', '{').replaceAll(']', '}'),
        noRows: this.$t("Nessun elemento trovato"),
        noResults: this.$t("Nessun risultato per la tua query di ricerca"),
        info: this.$t("Risultati da start a end su rows").replaceAll('[', '{').replaceAll(']', '}'),
      }
    });
  },
  methods: {
    remove: function(id){
      removeModal(this, () => {
        this.apiClient.removeSconto(id).then(() => {
          location.reload();//si, si dovrebbe fare una emit e intercettarla nel padre ecc.. non ho tempo
        });
      });
    }
  }
};
</script>
