<template>
  <argon-badge v-if="type == 'completo'" size="sm" color="success">{{$t('Completo')}}</argon-badge>
  <argon-badge v-else-if="type == 'rateizzato'" size="sm" color="success">{{$t('Rateizzato')}}</argon-badge>
  <argon-badge v-else-if="type == 'ricorrente'" size="sm" color="success">{{$t('Rateizzato')}}</argon-badge>
  <argon-badge v-else-if="type == 'stripe'" size="sm" color="success">{{$t('Stripe')}}</argon-badge>
  <argon-badge v-else-if="type == 'manuale'" size="sm" color="primary">{{$t('Manuale')}}</argon-badge>
  <argon-badge v-else size="sm" color="danger">{{$t('Sconosciuto')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "PagamentoTypeBadge",
  components: {ArgonBadge},
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
