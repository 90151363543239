<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky bg-white left-auto top-2 z-index-sticky`
    }`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
        <div class="pe-md-3 d-flex align-items-center ms-md-auto">
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none d-flex align-items-center">
            <a
                id="iconNavbarSidenav"
                href="#"
                class="p-0 nav-link text-white"
                @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li v-if="lang!=='it'" class="nav-item ps-3 d-flex align-items-center">
            <a
                href="#"
                class="px-0 nav-link font-weight-bold text-white"
                @click="setLanguage('it')"
            >
              <img alt="Italiano" src="@/assets/img/flags/it.png">
            </a>
          </li>
          <li v-if="lang!=='en'" class="nav-item ps-3 d-flex align-items-center">
            <a
                href="#"
                class="px-0 nav-link font-weight-bold text-white"
                @click="setLanguage('en')"
            >
              <img alt="English" src="@/assets/img/flags/en.png">
            </a>
          </li>
          <li v-if="lang!=='de'" class="nav-item ps-3 d-flex align-items-center">
            <a
                href="#"
                class="px-0 nav-link font-weight-bold text-white"
                @click="setLanguage('de')"
            >
              <img alt="Deutsch" src="@/assets/img/flags/de.png">
            </a>
          </li>
          <li class="nav-item ps-3 d-flex align-items-center">
            <a
                href="#"
                class="px-0 nav-link font-weight-bold text-white"
                @click="logout()"
            >
              <i class="fa fa-sign-out-alt me-sm-1"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    ...mapState(["isNavFixed", "darkMode", "lang"]),
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "logout", "setLanguage"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    }
  }
};
</script>
