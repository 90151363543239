<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div class="d-flex flex-row-reverse justify-content-between">
        <div
          class="text-center shadow icon icon-shape"
          :class="[
            typeof icon === 'object'
              ? `${icon.background} ${icon.shape}`
              : 'border-radius-md'
          ]"
        >
          <i
            class="text-lg opacity-10"
            :class="typeof icon === 'string' ? icon : icon.component"
            aria-hidden="true"
          ></i>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p class="mb-0 text-sm text-uppercase font-weight-bold">
              {{ title }}
            </p>
            <h5 :class="`mb-0 font-weight-bolder`">
              {{value}} <span class='mb-0 font-weight-light text-xs'>{{valueSubtitle}}</span>
            </h5>
            <h5 :class="`mb-0 font-weight-light text-xs`">
              {{previous}} <span>{{previousSubtitle}}</span>
            </h5>
            <!--  eslint-disable-next-line vue/no-v-html -->
            <p v-if="description" class="mt-2 mb-0" v-html="description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniStatisticsCard",
  props: {
    title: {
      type: [String],
      required: true
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    previous: {
      type: [String, Number],
      required: true,
    },
    valueSubtitle: {
      type: String,
      default: ""
    },
    previousSubtitle: {
      type: String,
      default: ""
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      shape: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
        shape: " border-radius-md",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
  },
};
</script>
