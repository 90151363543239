<template>
  <argon-badge v-if="proposition" size="sm" :color="yesStyle">{{$t('si')}}</argon-badge>
  <argon-badge v-else size="sm" :color="noStyle">{{$t('no')}}</argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "YesNoBadge",
  components: {ArgonBadge},
  props: {
    proposition: {
      type: Boolean,
      required: true
    },
    yesStyle: {
      type: String,
      default: "success"
    },
    noStyle: {
      type: String,
      default: "danger"
    }
  }
};
</script>
