<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-8">
        <form ref="form">
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t('Listino QR') }}</h5>
            </div>
            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Nome') }}</h6>
                  <multilang-input
                      id="descrizione"
                      v-model:value-it="listino.descrizione"
                      v-model:value-en="listino.descrizione_en"
                      v-model:value-de="listino.descrizione_de"
                      required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h6>{{ $t('Posizione') }}</h6>
                  <argon-input v-model="listino.posizione" type="text"/>
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="save"
                >{{$t('Salva')}}</argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4">
        <div class="card p-3 border-radius-xl bg-white">
          <div class="card-body">
            <div class="row mt-3">
              <div class="col-12">
                <img ref="qrcodeImg" style="width: 100%" :src="`https://api.the-co.ch/v2/?action=admin_listino_qrcode&id=${listino.id}`">
              </div>
              <div class="button-row d-flex mt-2">
                <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto mb-0"
                    @click="download"
                >Download</argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="listino.id !== 0" class="row mt-4">
      <div class="col-12">
        <form>
          <div class="card p-3 border-radius-xl bg-white">
            <div class="card-header">
              <h5 class="font-weight-bolder mb-0">{{ $t("Servizi")}}</h5>
              <div class="button-row d-flex">
                <router-link :to="{name:'ServizioListino', params:{id: 0}, query:{lid: listino.id}}" class="ms-auto">
                  <argon-button
                      type="button"
                      color="dark"
                      variant="gradient"
                  >{{$t('Nuovo')}}</argon-button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <servizio-listino-data-table :key="serviziListinoKey" :servizi_listino="serviziListino"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";
import MultilangInput from "@/thecomponents/MultilangInput.vue";
import {savedModal, unsavedModal} from "@/modals";
import {mapState} from "vuex";
import ServizioListinoDataTable from "@/parts/DataTables/ServizioListinoDataTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  name: "Listino",
  components: {ArgonInput, ServizioListinoDataTable, MultilangInput, ArgonButton},
  data() {
    return {
      listino: {
        id: 0,
        descrizione: '',
        descrizione_en: '',
        descrizione_de: '',
        posizione: ''
      },
      listinoKey: Math.random(),
      serviziListino: [],
      serviziListinoKey: Math.random(),
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    if (this.$route.query.id) {
      this.listino.id = parseInt(this.$route.query.id);
    }

    this.loadListino(this.$route.params.id)
  },
  methods: {
    loadListino(id) {
      this.listino.id = parseInt(id);
      if (this.listino.id !== 0) {
        this.apiClient.getListino(id).then(response => {
          if (response.data) {
            this.listino = response.data;
            this.listinoKey = Math.random();
          }
        });
        this.apiClient.getServiziListino(id).then(response => {
          if(response.data){
            this.serviziListino = response.data;
            this.serviziListinoKey++;
          }
        });
      }
    },
    save() {
      if (!this.$refs.form.reportValidity()) {
        return;
      }
      this.apiClient.saveListino(this.listino).then(response => {
        if (response.data && response.data.success) {
          savedModal(this);
          this.$router.push({name: 'Listini'});
        } else {
          unsavedModal(this);
        }
      });
    },
    download() {
      const imageElement = this.$refs.qrcodeImg;
      const imageUrl = imageElement.src;

      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.target = '_blank';
      anchor.download = 'qrcode.png';

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }
};
</script>
