import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import { createI18n } from 'vue-i18n';
import apiClient from "./api-client";

const defaultLang = localStorage.getItem('lang') || process.env.VUE_APP_DEFAULT_LANG || 'it';
store.state.lang = defaultLang;

const i18n = createI18n({
    legacy: false,
    locale: defaultLang,
    messages: {
        it: require('./locales/it.json'),
        en: require('./locales/en.json'),
        de: require('./locales/de.json')
    },
});

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueSweetalert2);
appInstance.use(i18n);

appInstance.config.globalProperties.apiClient = apiClient;
apiClient.setAuthorizationToken(store.state.bearerToken);

appInstance.mount("#app");
